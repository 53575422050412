.page-product-list {
  /*background-image: url('../images/undraw_businesswoman_pc12.svg');
  background-repeat: no-repeat;
  background-size: 115%;
  background-position: 49px -40px;*/
    .product-intro {
        padding-top:50px;
        padding-bottom: 30px;
    }
    .service{
        margin-bottom:20px;
    }
  @include media-breakpoint-up(sm) {
    background-size: 90%;
    background-position: 159px -40px;
  }
  @include media-breakpoint-up(md) {
    background-size: 85%;
    background-position: 199px -80px;
  }
  @include media-breakpoint-up(lg) {
    background-size: 60%;
    background-position: 100% -110px;
  }
}
