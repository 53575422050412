.intro {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-top: 200px;
  padding-bottom: 100px;
  @include media-breakpoint-up(sm) {
    padding-top: 160px;
    padding-bottom: 80px;
  }
  @include media-breakpoint-up(md) {
    padding-top: 160px;
    padding-bottom: 80px;
  }
  @include media-breakpoint-up(lg) {
    padding-top: 155px;
    padding-bottom: 100px;
  }
  h1 {
    color: $primary;
    font-size: 2.4rem;
    line-height: 1.2;
    font-family: $font-family-heading;
    width: 70%;
      background: #fff;
    @include media-breakpoint-up(sm) {
      width: 70%;
    }
    @include media-breakpoint-up(md) {
      font-size: 3rem;
      width: 70%;
    }
    @include media-breakpoint-up(lg) {
      font-size: 3rem;
      width: 50%;
    }
  }
  p {
    /*width: 40%;*/
    font-size: 1.1rem;
    font-weight: lighter;
    line-height: 1.4;
    color: $secondary;
    @include media-breakpoint-up(sm) {
      /*width: 60%;*/
    }
    @include media-breakpoint-up(md) {
      /*width: 60%;*/
    }
    @include media-breakpoint-up(lg) {
      /*width: 60%;*/
    }
  }
}
.intro-small {
  padding-top: 100px;
  padding-bottom: 30px;
}
