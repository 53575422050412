.feature {
  height: 100%;
  border: 2px solid #eaeaea;
  padding: 20px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  .feature-image {
    flex: 0 0 auto;
    width: 80px;
    height: 80px;
    display: flex;
    margin-bottom: 20px;
    text-align: center;
  }
  img {
    width: 100%;
    height: auto;
  }
  .feature-title {
    font-size: 1.5rem;
    font-weight: 300;
    line-height: 1.2;
  }
  .feature-content {
    margin-bottom: 0;
    margin-top: auto;
    p {
      margin: 0;
      strong {
        color: $secondary;
        text-transform: uppercase;
        font-size: 0.8rem;
        font-weight: 700;
      }
      em {
        color: $primary;
        font-style: normal;
      }
    }
  }
}
