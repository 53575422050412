.sub-footer-strip {
  // box-shadow: 0px -4px 9px 0 rgba(36, 50, 66, 0.15);
  background: darken($secondary, 10%);
  padding-top: 10px;
  padding-bottom: 10px;
}
.sub-footer {
  display: flex;
  justify-content: space-between;
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      list-style: none;
      display: block;
      font-size: 0.8rem;
      color: #fff;

      a {
        color: #fff;
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }
      span {
        display: inline-block;
        height: 40px;
        padding: 10px 0px 10px 8px;
        font-weight: bold;
        color: #fff;
      }
    }
    @include media-breakpoint-up(sm) {
      li {
        display: inline-block;
        margin-left: 10px;
        &:first-of-type {
          margin-left: 0;
        }
      }
    }
  }
}
