.logo {
  display: none;
  @include media-breakpoint-up(sm) {
    display: block;
    height: 225px;
      margin-left:-60px;
  }
  img {
    height: 100%;
  }
  a {
    display: block;
    width: 100%;
    height: 100%;
  }
}

.logo-mobile {
  display: block;
  width: 30px;
  @include media-breakpoint-up(sm) {
    display: none;
  }
  img {
    width: 500%;
    height: auto;
    margin-left: -25px;
  }
  a {
    display: block;
    width: 100%;
    height: 100%;
  }
}
