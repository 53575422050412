// Type Styling for body content
.content {
  ul,
  ol {
    margin-top: 10px;
    margin-bottom: 20px;
    li {
      margin-bottom: 5px;
      margin-left: 20px;
    }
  }
  a {
    text-decoration: underline;
  }
  p {
    color: rgb(78, 78, 78);
    font-family: $font-family-base;
  }
  h1 {
    font-family: $font-family-heading;
    line-height: 1.2;
    font-weight: $font-weight-light;
    color: #333333;
    @include media-breakpoint-up(md) {
      line-height: 1.2;
      font-weight: $font-weight-light;
    }
  }
  h2 {
    font-family: $font-family-heading;
    line-height: 1.4;
    font-weight: 400;
    color: #333333;
    @include media-breakpoint-up(md) {
      line-height: 1.4;
    }
  }
  // Subheading
  h3 {
    color: $primary;
    line-height: 1.4;
    font-weight: $font-weight-thin;
    letter-spacing: 1px;
    @include media-breakpoint-up(md) {
      line-height: 1.4;
      letter-spacing: 1.8px;
      font-weight: $font-weight-thin;
    }
  }
}

.corporate-color-1 {
    color: $corporate-color-1;
}

.corporate-color-2 {
    color: $corporate-color-2;
}

.corporate-color-3 {
    color: $corporate-color-3;
}

.corporate-color-4 {
    color: $corporate-color-4;
}

.corporate-color-5 {
    color: $corporate-color-5;
}
