$color-main: $primary;
$color-active: #fff;
$color-link: #fff;

$button-height: 27px;
$button-width: 35px;

.button_container {
  position: fixed;
  top: 5%;
  right: 2%;
  height: $button-height;
  width: $button-width;
  cursor: pointer;
  z-index: 100;
  transition: opacity 0.25s ease;

  &:hover {
    opacity: 0.7;
  }

  &.active {
    .top {
      transform: translateY(11px) translateX(0) rotate(45deg);
      background: $color-active;
    }
    .middle {
      opacity: 0;
      background: $color-active;
    }

    .bottom {
      transform: translateY(-11px) translateX(0) rotate(-45deg);
      background: $color-active;
    }
  }

  span {
    background: $color-main;
    border: none;
    height: 5px;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    transition: all 0.35s ease;
    cursor: pointer;

    &:nth-of-type(2) {
      top: 11px;
    }

    &:nth-of-type(3) {
      top: 22px;
    }
  }
}

.main-menu-mobile {
  position: fixed;
  background: $color-main;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.35s, visibility 0.35s, height 0.35s;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  &.open {
    opacity: 0.9;
    visibility: visible;
    height: 100%;
    z-index: 10;
    li {
      animation: fadeInRight 0.5s ease forwards;
      animation-delay: 0.35s;
      &:nth-of-type(2) {
        animation-delay: 0.4s;
      }
      &:nth-of-type(3) {
        animation-delay: 0.45s;
      }
      &:nth-of-type(4) {
        animation-delay: 0.5s;
      }
      &:nth-of-type(5) {
        animation-delay: 0.55s;
      }
      &:nth-of-type(6) {
        animation-delay: 0.6s;
      }
    }
  }
  ul {
    font-size: 2rem;
    font-family: $font-family-heading;
    text-align: center;
    list-style: none;
    padding: 0;
    margin: 0;
    flex: 0;
    li {
      display: block;
      position: relative;
      opacity: 0;
      a {
        display: block;
        position: relative;
        color: $color-link;
        text-decoration: none;
        overflow: hidden;
        &:hover:after,
        &:focus:after,
        &:active:after {
          width: 100%;
        }

        &:after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 50%;
          width: 0%;
          transform: translateX(-50%);
          height: 3px;
          background: $color-link;
          transition: 0.35s;
        }
      }
    }
  }
}

@keyframes fadeInRight {
  0% {
    opacity: 0;
    left: 20%;
  }
  100% {
    opacity: 1;
    left: 0;
  }
}

.lock-scroll {
  overflow: hidden;
}
